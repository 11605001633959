import Compress from "compress.js";

const compress = new Compress();

export default function compressFile(files) {
  return compress
    .compress(files, {
      size: 4, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 1920, // the max width of the output image, defaults to 1920px
      maxHeight: 1920, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
      rotate: false, // See the rotation section below
    })
    .then((data) => {
      console.log("this is data", data[0]);
      const base64str = data[0].data;
      const imgExt = data[0].ext;
      const file = Compress.convertBase64ToFile(base64str, imgExt);
      var file_new = new File([new Blob([file])], data[0].alt, {
        type: imgExt,
        lastModified: new Date().getTime(),
      });
      return file_new;
    });
}
